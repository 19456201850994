<template>
  <div class="news">
    <head-nav></head-nav>
    <idea-details-img></idea-details-img>
    <div class="about-nav">
      <div class="wraps">
        <div class="about-navs clearfix">
          <span class="about-box">
            <router-link
              to="/"
              tag="span"
              class="about-list"
            >
            {{$t('about.home')}}
            </router-link>
            <span class="about-lists">
              <i class="about-listicon iconfont icon-youbianjiantou"></i>
              <router-link
                tag="span"
                to="/quality-assurance"
                class="about-info"
              >
                {{$t('about.quality')}}
              </router-link>
              <i class="about-listicon iconfont icon-youbianjiantou"></i>
            </span>

            <span class="about-lists">

              <span
                class="about-info"
                @click="$router.push(item.path)"
                v-for="item in aboutBreadcrumb"
                :key="item.label"
              >
                <span class="about-info-list">
                  <i class="iconfont icon-youbianjiantou"></i>
                  {{$t(item.label)}} </span>
              </span>
            </span>

            <span>{{post.title}}</span>

          </span>

          <span
            class="return"
            @click="onClickBack()"
          >
            <span>{{$t('toback')}}</span>
            <img
              src='@/assets/image/product/fanhui.png'
              alt=""
            ></span>
        </div>
      </div>
    </div>
    <div class="wrap">
      <h3 class="news-title">
        {{post.title}}
      </h3>

      <div
        class="news-content"
        v-html="post.content"
      >

      </div>

    </div>

    <footers></footers>
  </div>
</template>

<script>
import '@/assets/icon/iconfont.css';

import HeadNav from './head-nav';
import IdeaDetailsImg from './idea-details-img';
import Footers from './footers';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    HeadNav,
    IdeaDetailsImg,
    Footers,
  },

  computed: {
    post() {
      return this.$t('quality-assurance.assuranceInfo')[this.dimensions[this.id]];
    },
  },

  data() {
    return {
      dimensions: {
        1: 0,
        2: 1,
        3: 2,
        4: 3,
      },
    };
  },

  methods: {
    onClickBack() {
      this.$router.push({
        path: '/quality-assurance',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  .about-nav {
  padding: 30px 0;
  border-bottom: 1px solid #eeeeee;
  position: relative;

  .wraps{
    width: 1200px;
    margin: 0 auto;
  }

  .about-navs {
    width: 100%;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;

    .about-box {
      width: 70%;

      .about-list {
        color: #000;
        margin-left: 30px;
      }

      .about-lists {
        .about-listicon {
          display: inline-block;
          padding: 0 10px;
        }
        .about-info {
          color: #666;
          display: inline-block;
          cursor: pointer;

          &:nth-child(2) {
            .about-info-list{
              position: relative;
              top: 2px;
              left: 0;
              overflow: hidden;
              display: inline-block;
            }
          }

          &-list {
            display: inline-block;
            width: 300px;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;

            .icon-youbianjiantou {
              margin: 0 10px;
            }
          }

          &:nth-child(1) {
            .about-info-list {
              width: auto;
            }

            .icon-youbianjiantou:nth-child(1) {
              display: none;
            }
          }
        }
      }
    }

    .return {
      width: 30%;
      display: inline-block;
      text-align: right;
      position: relative;
      cursor: pointer;

      img {
        position: relative;
        top: -1px;
        left: 4px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .about-nav,
  .about-nav .about-navs {
    width: 1200px !important;
  }

  .about-nav .about-navs .return img {
    left: 0;
  }
}
  .wrap {
    padding: 40px 0 150px 0;

    .news-title {
      text-align: center;
      margin-top: 30px;
      font-size: 24px;
      font-weight: 500;
    }

    .news-info {
      margin-top: 40px;
      padding: 14px 0;
      border-bottom: 1px solid #eee;

      .news-time {
        margin-right: 64px;
      }
    }

    .news-content {
      font-size: 14px;
      line-height: 20px;

      ::v-deep p {
        margin-top: 30px;

        img {
          margin: 30px auto;
        }
      }
    }

    .new-option-link {
      padding: 16px 0;
      border-top: 1px solid #eeeeee;
      margin-top: 30px;

      p {
        margin-top: 16px;
      }
    }
  }
}
</style>
